import React, { useState } from "react";
import styles from "./Face.module.scss";
import sheaface from "../../assets/Images/Shea.png";
import LeftEye from "../../components/LeftEye";
import RightEye from "../../components/RightEye";

const Face = (props) => {
  const { angle } = props;
  const [eyesStraight, changeAngle] = useState(true);

  const newAngle = eyesStraight ? 0 : angle;

  const myStyle = {
    transform: `rotate(${newAngle}deg)`,
    // animation: `spin 4s linear`,
  };

  // "STYLE" NEEDS TO BE AN OBJECT!!!!!!!!!!!!!!!!!!!!!

  const print = () => {
    console.log(eyesStraight);
  };

  return (
    <>
      <section className={styles.face}>
        <img
          src={sheaface}
          className={styles.sheaFace}
          onClick={() => {
            changeAngle(!eyesStraight);
            print();
          }}
        />
        <div className={styles.leftEye} style={myStyle}>
          <LeftEye />
        </div>
        <div className={styles.rightEye} style={myStyle}>
          <RightEye />
        </div>
      </section>
    </>
  );
};

export default Face;
