import React from "react";
import styles from "./Button.module.scss";

const Button = ({ text, handleClick }) => {
  return (
    <>
      <button onClick={handleClick}>{text}</button>
    </>
  );
};

export default Button;
