import React from "react";
import logo from "./logo.svg";
import styles from "./App.module.scss";
import Face from "./containers/Face";
import Button from "./components/Button";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [facesNumber, updateFacesNumber] = useState();
  const [faceArray, updateFaceArray] = useState([]);

  const getRandom = () => Math.floor(Math.random() * 360);

  useEffect(() => getRandomNumber(), []);

  const getRandomNumber = () => {
    fetch(
      "https://www.random.org/integers/?num=1&min=1&max=6&col=1&base=10&format=plain&rnd=new"
    )
      .then((result) => result.json())
      .then((result) => {
        console.log(result);
        updateFacesNumber(result);
      });
  };

  const printFaces = () => {
    const newArray = [];
    for (let i = 1; i <= facesNumber; i++) {
      newArray.push(
        <div>
          <Face angle={getRandom()} />
        </div>
      );
      console.log("j");
    }
    updateFaceArray(newArray);
  };

  return (
    <>
      <Button text={"Click me"} handleClick={printFaces} />

      <div className={styles.hallOfFaces}>{faceArray}</div>
    </>
  );
}

export default App;
