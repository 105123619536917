import React from "react";
import styles from "./RightEye.module.scss";
import Right from "../../assets/Images/right.png";

const RightEye = props => {
  const { angle } = props;

  return (
    <>
      <img src={Right} alt="" className={styles.rightEye} />
    </>
  );
};

export default RightEye;
